
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {loadPaginatedEstoreTrans}  from "@/api/estore";
import EstoreTabNavigator from "@/components/cards/EstoreTabNavigator.vue";

export default defineComponent({
  name: "reorder",
  components: {
    EstoreTabNavigator,
  },
  data() {
    return { 
      list : [],
      loading: true,
      isBCOAccount : false,
      to_date :  new Date().toISOString().substr(0, 10),
      from_date: new Date().toISOString().substr(0, 10),
    }
  },
  mounted(){
      this.estoreTransactionsHistory();
      
  },
  methods : {
    async estoreTransactionsHistory(){
      this.loading = true; 
      let params = {
        "page" : 1, 
        "f" : this.from_date + " 00:00:00",
        "t" : this.to_date + " 23:59:59"
      }

      
      const response = await loadPaginatedEstoreTrans(params);
      this.list = response.data.pageList;
      this.loading = false;
      this.isBCOAccount = Boolean(localStorage.getItem("isBCOAccount"));
    },
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("reorderTab") || "0");
      setCurrentPageTitle("Estore Transactions");
      
    });
    
  }
});
